@import 'assets/css/_variables';

.PrettyBandCombo {
  // Common
  .leg {
    display: inline-block;
    margin-right: $grid-gutter-width/4;

    .band {
      background-color: transparent;
      padding: 1px 5px;
      font-size: $font-size-lg;
      border: 1px solid transparent;
      text-align: center;
      margin: 0.5rem auto;
    }
  }

  // New bands only
  &.new {
    .leg .band {
      font-size: 0.95rem;
      width: 56px;
      min-height: 24px;

      .symbol {
        font-weight: $font-weight-bold;
        text-shadow: 0px 0px 3px rgba(0, 0, 0, 0.5);
      }
    }
  }

  // Old bands only
  &.old {
    .leg {
      text-align: center;
      background-color: $gray-500;
      box-shadow: inset 0px 0px 0px 8px $body-bg;

      .band {
        width: 32px;
        min-height: 16px;
      }
    }
  }
}
